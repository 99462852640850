/*
------------------------------
    : Custom - Toasts js :
------------------------------
*/
"use strict";
$(document).on("ready turbolinks:load", function() {
  /* -- Toasts - Bottom Right -- */
  $("#bottom-right-toasts").appendTo($("body")), 
  $("#bottom-right-toasts").toast({
      delay: 2000
  }),
  $("#bottom-right-toasts-btn").click(function() {
      $("#bottom-right-toasts").toast("show")
  })
});