$(document).on("ready turbolinks:load", function () {
  $.sidebarMenu = function (menu) {
    const animationSpeed = 300;
    const subMenuSelector = ".vertical-submenu";
    const leftbar = document.querySelector(".leftbar");
    const menuToggle = document.getElementById("menu-toggle");
    const menuClose = document.getElementById("menu-close");

    function openLeftbar() {
      if (!leftbar.classList.contains("open")) {
        leftbar.classList.add("open");
      }
    }

    function closeLeftbar() {
      if (leftbar.classList.contains("open")) {
        leftbar.classList.remove("open");
        closeAllSubmenus();
      }
    }

    function closeAllSubmenus() {
      document.querySelectorAll(subMenuSelector).forEach(function (submenu) {
        if (submenu.style.display !== "none") {
          $(submenu).slideUp(animationSpeed, function () {
            submenu.classList.remove("menu-open");
            submenu.parentNode.classList.remove("open-sub");
          });
        }
      });
    }

    function handleMenuToggle(link, checkElement) {
      if (
        checkElement &&
        checkElement.style.display !== "" &&
        checkElement.style.display !== "none"
      ) {
        $(checkElement).slideUp(animationSpeed, function () {
          checkElement.classList.remove("menu-open");
          link.parentNode.classList.remove("open-sub");
        });
      } else if (checkElement) {
        const parent = link.closest("ul");
        const visibleUl = parent ? parent.querySelectorAll("ul:visible") : [];
        visibleUl.forEach(function (ul) {
          $(ul).slideUp(animationSpeed);
          ul.classList.remove("menu-open");
          ul.parentNode.classList.remove("open-sub");
        });
        $(checkElement).slideDown(animationSpeed, function () {
          checkElement.classList.add("menu-open");
          link.parentNode.classList.add("open-sub");
        });
      }
    }
    function handleSidebarMenuClick(e) {
      const link = e.currentTarget;
      const checkElement = link.nextElementSibling;

      if (checkElement && checkElement.matches(subMenuSelector)) {
        if (!leftbar.classList.contains("open")) {
          openLeftbar();
          setTimeout(function () {
            handleMenuToggle(link, checkElement);
          }, animationSpeed);
        } else {
          handleMenuToggle(link, checkElement);
        }
        e.preventDefault();
      }
    }

    const menuLinks = document.querySelectorAll(".vertical-menu li a");
    menuLinks.forEach(function (link) {
      link.addEventListener("click", handleSidebarMenuClick);
    });

    menuToggle.addEventListener("click", function () {
      if (leftbar.classList.contains("open")) {
        closeLeftbar();
      } else {
        openLeftbar();
      }
    });
    menuClose.addEventListener("click", function () {
      closeLeftbar();
    });
  };
});
document.addEventListener("turbolinks:request-start", function () {
  var overlay = document.createElement("div");
  overlay.className = "turbolinks-overlay";
  document.body.appendChild(overlay);
  overlay.style.display = "block";
});

document.addEventListener("turbolinks:load", function () {
  var overlay = document.querySelector(".turbolinks-overlay");
  if (overlay) {
    overlay.style.display = "none";
    document.body.removeChild(overlay);
  }
});
